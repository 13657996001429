import type { Icon, IconWeight } from '@phosphor-icons/react'
import { IconBase } from '@phosphor-icons/react'
import type { ReactElement } from 'react'
import { forwardRef } from 'react'

const lemonLogoSvg = (
  <>
    <path d="M135.995 248V136H247.995C247.995 197.856 197.851 248 135.995 248Z" />
    <path d="M135.995 136.139L135.995 136H23.9946L23.9947 248C85.8043 248 135.92 197.931 135.995 136.139Z" />
    <path d="M23.9946 24H135.994L135.995 136C74.1389 136 23.9946 85.8558 23.9946 24Z" />
    <path d="M247.995 80C247.995 110.928 222.923 136 191.995 136C161.067 136 135.995 110.928 135.995 80C135.995 49.0721 161.067 24 191.995 24C222.923 24 247.995 49.0721 247.995 80Z" />
  </>
)

const weights = new Map<IconWeight, ReactElement>([
  ['regular', lemonLogoSvg],
  ['fill', lemonLogoSvg],
])

const Lemon: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
))

Lemon.displayName = 'Lemon'

export default Lemon
